import { Button } from 'antd';
import { navigate } from 'gatsby-link';
import React from 'react';
import { Roles } from '../../../utils/roles';
import { getUser } from '../../../utils/validateUserToken';
import './DriversManagement.scss';
import DriversManagementTable from './DriversManagementTable';

export default function DriversManagement() {
  const user = getUser();
  const isSysAdmin = user?.roles?.includes(Roles.SYS_ADMIN);
  const isAdmin = user?.roles?.includes(Roles.ADMIN);
  const isFmAppEnabled = user.is_fairmatic_app_enabled;
  const enableEditDrivers = isAdmin || isSysAdmin;
  const enableAddDrivers = isSysAdmin;
  return (
    <div className="playground">
      <div className="page_header">
        <div className="page_header_div" />
        {isFmAppEnabled && (
          <div>
            {enableEditDrivers && (
              <Button
                type="secondary mr-3 br-5 edit_drivers_button"
                onClick={() => navigate('/app/dashboard/edit-drivers')}
              >
                Edit Drivers
              </Button>
            )}
            {enableAddDrivers && (
              <Button
                type="primary"
                className="pr-30 ml-2 br-5"
                onClick={() => navigate('/app/dashboard/add-drivers')}
              >
                Add Drivers
              </Button>
            )}
          </div>
        )}
      </div>
      <DriversManagementTable
        isSysAdmin={isSysAdmin}
        isFmAppEnabled={isFmAppEnabled}
      />
    </div>
  );
}
